function createModal(param1,param2,param3) {
    //console.log('createModal');
    var modal_destination = $('#'+param2);
    var modal_trigger = document.querySelector("#"+param3);

    setTimeout(function(){
        modal_destination.load(param1, '', function(){
            //$modal.modal();
            modal_trigger.setAttribute("data-bs-target","#"+param2);
            modal_trigger.click();
            //$('#modaltrigger').attr('data-bs-target','#'+param2);
		    //$('#modaltrigger').click();
        });
    }, 100);
}

function updateModal(param1,param2) {
    //console.log('updateModal');
    var modal_destination = $('#'+param2);

    setTimeout(function(){
        modal_destination.load(param1, '', function(){
        });
    }, 100);
}

var dt_lang_th = {
    "lengthMenu": "แสดง _MENU_ รายการต่อหน้า",
    "zeroRecords": "ไม่พบข้อมูล",
    "info": "กำลังแสดงผลหน้าที่ _PAGE_ จากทั้งหมด _PAGES_ หน้า",
    "infoEmpty": "ไม่มีข้อมูล",
    "infoFiltered": "(กรองจากทั้งหมด _MAX_ รายการ)",
    "search": "คำค้นหาแบบเร็ว",
    "paginate": {
        "first":      "หน้าแรก",
        "last":       "หน้าสุดท้าย",
        "next":       "หน้าต่อไป",
        "previous":   "หน้าที่แล้ว"
    },
    decimal: '.',
    thousands: ',',
};

var dt_button_1 = [
    { extend: 'print', text: 'พิมพ์', className: 'btn dark btn-outline' },
	{ extend: 'copy', text: 'คัดลอก', className: 'btn red btn-outline' },
	//{ extend: 'pdf', text: 'พิมพ์', className: 'btn green btn-outline' },
	//{ extend: 'excel', text: 'เอ็กเซล', className: 'btn yellow btn-outline ' },
	//{ extend: 'csv', text: 'พิมพ์', className: 'btn purple btn-outline ' },
	{ extend: 'colvis', text: 'แสดงคอลัมน์', className: 'btn dark btn-outline'}
];

var dt_dom_1 = "<'row'<'col-md-6 col-sm-12'l><'col-md-6 col-sm-12 dataTables_filter pt-0'B>><'table-scrollable't><'row'<'col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'i><'col-md-7 col-sm-12'p>>";

var glb_msg_lblDeleteConfirm = "ยืนยันการลบข้อมูล";
var glb_msg_lblYes = "ยืนยัน";
var glb_msg_lblCancel = "ยกเลิก";
var glb_msg_lblSuccessful = "ดำเนินการเรียบร้อย";
var glb_msg_lblNotYetDelete = "ข้อมูลยังไม่ถูกลบ";
var glb_msg_lblCloseWindow = "ปิดหน้าต่างนี้";